const dataFaqs = [
  {
    id: 1,
    title: "How does Soleil work?",
    text: "Soleil operates through a decentralized marketplace where AI developers can showcase their models, algorithms, and datasets. Smart contracts enable secure trading and monetization of AI assets, while oracles facilitate interaction with real-world data on the blockchain.",
    show: "",
  },
  {
    id: 2,
    title: "What is the purpose of Soleil's governance framework?",
    text: "Soleil's decentralized governance model allows stakeholders to participate in decision-making processes regarding platform upgrades, protocol improvements, and community initiatives, ensuring adaptability and alignment with users' interests.",
    show: "",
  },
  {
    id: 3,
    title: "What is the native utility token of Soleil, and what is its role?",
    text: "The native utility token of Soleil is SIL. It serves as the primary medium of exchange, governance voting rights, incentivization for developers and contributors, and participation in platform governance and decision-making processes.",
    show: "",
  },
  {
    id: 4,
    title: "How are SIL tokens distributed?",
    text: "SIL tokens are distributed through a transparent mechanism, with allocations reserved for contributors, developers, early adopters, and ecosystem growth initiatives.",
    show: "",
  },
  {
    id: 5,
    title: "What are some potential use cases of Soleil?",
    text: "Soleil can be used for predictive analytics, medical diagnosis, supply chain optimization, and more, enabling enterprises to leverage AI technologies securely and efficiently.",
    show: "",
  },
  {
    id: 6,
    title: "What are the phases of Soleil's roadmap?",
    text: "Soleil's roadmap includes phases for development, integration with leading AI frameworks, adoption through partnerships, and expansion to explore additional functionalities and use cases.",
    show: "",
  },
  {
    id: 7,
    title: "Who founded Soleil, and what is their vision?",
    text: "Soleil was founded by a team of passionate AI researchers, blockchain enthusiasts, and industry experts dedicated to democratizing access to AI technologies and fostering innovation in the field.",
    show: "",
  },
  {
    id: 8,
    title: "Is Soleil a financial investment opportunity?",
    text: "No, Soleil's whitepaper emphasizes that it is for informational purposes only and does not constitute investment or financial advice. Readers are encouraged to conduct their own research before participating in any token sale or investment opportunity.",
    show: "",
  },
];

export default dataFaqs;
