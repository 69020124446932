import Web3 from "web3";
import { presaleAddress } from "./constants";
import presaleAbi from "./presaleAbi.json";

let constant = {
  contractAddress: presaleAddress,
  abi: presaleAbi,
};

var web3 = new Web3(window.ethereum);
var presaleContract = new web3.eth.Contract(
  constant.abi,
  constant.contractAddress
);

export default presaleContract;
