import { useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Banner(props) {
  const sm = useMediaQuery("(max-width:600px)");
  const md = useMediaQuery("(max-width:1024px)");
  return (
    <section className="banner" id="home">
      <div className="shape right"></div>
      <div className="container big">
        <div
          className="row"
          style={{
            flexDirection: md && "column-reverse",
            padding: md && "25px 5%",
          }}
        >
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading">
                  Unlocking the Future of AI with <span>SOLEIL</span>
                </h2>
                <p className="desc">
                  Discover the revolutionary decentralized platform merging AI
                  and blockchain, empowering developers and enterprises to
                  reshape industries securely and collaboratively
                  <br />
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: sm ? "column" : "row",
                    gap: sm ? "15px" : "25px",
                  }}
                >
                  <Link
                    to="/presale"
                    className="action-btn"
                    style={{
                      background: "#0d6efd",
                      maxWidth: 180,
                      height: 50,
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        color: "#fff",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Explore Now{" "}
                      <i
                        className="bi bi-arrow-right"
                        style={{ fontSize: 26 }}
                      ></i>
                    </span>
                  </Link>
                  <ul className="list-social" style={{ marginTop: "0" }}>
                    <li>
                      <a target="_blank" href="https://x.com/Soleil_app_">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-twitter-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://t.me/soleilcrypto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-telegram"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://discord.com/invite/2H5GyY7Fvc"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-discord"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="http://t.me/soleildiscussion">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-chat-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="pay">
                <h6>We are Monteno NFT</h6>

                <div className="list">
                  <p>We accept:</p>

                  <ul>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-01"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-02"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-03"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-04"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-05">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-06"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__right">
              <div
                className="image"
                style={{ display: "grid", placeItems: "center" }}
              >
                <img src="/new_hero.png" alt="" />
              </div>
              {/* 
              <div className="price">
                <div className="icon">
                  <img src={img2} alt="cyfonii" />
                </div>
                <div className="content">
                  <p>Current Bid</p>
                  <h5>2.26 ETH</h5>
                </div>
              </div>

              <div className="owner">
                <div className="image">
                  <img src={img3} alt="cyfonii" />
                </div>
                <div className="content">
                  <h5>Leslie Alexander</h5>
                  <p>@leslie754</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
