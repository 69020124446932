import CTA from "./CTA";

export default function AboutContent(props) {
  const { textSectionOrder, textSection, imageOrder, image } = props;

  return (
    <>
      <div className="container">
        <div className="row g-4 g-xl-5 align-items-center justify-content-between">
          {/* Text Content */}
          <div className="col-12 col-lg-7 col-xl-6">
            <div>
              <h2
                class="h2 fw-bold mb-3"
                style={{
                  fontSize: 36,
                }}
              >
                About Soleil
              </h2>
              <p class="fz-18">
                Soleil was founded by a team of passionate AI researchers,
                blockchain enthusiasts, and industry experts dedicated to
                democratizing access to AI technologies and fostering innovation
                in the field.
                <br />
                <br />
                With a shared vision of creating a more inclusive and
                decentralized future, our team brings together diverse skill
                sets and experiences to drive the development and adoption of
                Soleil.
              </p>
              {/* <a class="btn btn-primary rounded-pill mt-4" href="/#">
                Know More
              </a> */}
            </div>
          </div>

          {/* Image */}
          <div className="col-12 col-lg-5">
            <img className="rounded" src="/new_about.png" alt="" />
          </div>
        </div>
      </div>
      <div className="divider" />

      <CTA
        backgroundColor="primary" // try 'success', 'warning', 'danger', 'info' etc
        text="Invest in Soleil SIL Token Presale now and get up to 4x returns!"
        buttonText="Buy Presale"
        buttonColor="warning"
        buttonURL="/presale"
        buttonIcon=""
      />
      <div className="divider" />
      <div className="container">
        <div className="row g-4 g-xl-5 align-items-center justify-content-between">
          {/* Image */}
          <div className="col-12 col-lg-5">
            <img className="rounded" src="/new_about.png" alt="" />
          </div>
          {/* Text Content */}
          <div className="col-12 col-lg-7 col-xl-6">
            <div>
              <h2
                class="h2 fw-bold mb-3"
                style={{
                  fontSize: 36,
                }}
              >
                Soleil Staking
              </h2>
              <p class="fz-18">
                Soleil offers a robust staking mechanism to incentivize
                participants and enhance network security. By staking SIL
                tokens, users can earn attractive rewards while actively
                contributing to the platform's stability. The staking program
                provides an Annual Percentage Rate (APR) of 30%, with flexible
                lock-up periods to cater to different investment strategies.
                Users can choose from lock times of 7, 14, 21, 30, or 60 days,
                allowing them to select the duration that best suits their
                needs. This staking model not only encourages long-term holding
                of SIL tokens but also promotes active engagement within the
                Soleil ecosystem, fostering a loyal and supportive community.
              </p>
              {/* <a class="btn btn-primary rounded-pill mt-4" href="/#">
                Know More
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
