import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Box } from "@mui/material";
export default function TimerCountDown({ time }) {
  let interval = useRef();

  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });

  const startTime = async (time) => {
    // let countDownDate = moment("Oct 15,2021 17:45:00").format("x");
    interval = setInterval(() => {
      var jun = moment().format("x");
      //  let a = jun.tz("Europe/Belgrade").format("x");
      const distance = +time - +jun;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    if (time) {
      startTime(+time * 1000);
    }
  }, [time]);
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "25px",
        padding: "15px 0",
      }}
    >
      <Box
        style={{
          width: 60,
          height: 60,
          background: "#000",
          border: "1px solid rgba(255,255,255,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          fontSize: 20,
          fontFamily: "Bai Jamjuree",
        }}
      >
        {countTime.time_days}D
      </Box>
      <Box
        style={{
          width: 60,
          height: 60,
          background: "#000",
          border: "1px solid rgba(255,255,255,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          fontSize: 20,
          fontFamily: "Bai Jamjuree",
        }}
      >
        {countTime.time_Hours}H
      </Box>
      <Box
        style={{
          width: 60,
          height: 60,
          background: "#000",
          border: "1px solid rgba(255,255,255,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          fontSize: 20,
          fontFamily: "Bai Jamjuree",
        }}
      >
        {countTime.time_Minusts}M
      </Box>
      <Box
        style={{
          width: 60,
          height: 60,
          background: "#000",
          border: "1px solid rgba(255,255,255,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          fontSize: 20,
          fontFamily: "Bai Jamjuree",
        }}
      >
        {countTime.time_seconds}S
      </Box>
    </Box>
  );
}
