import React, { useState } from "react";

function Nft(props) {
  const [dataBlock] = useState({
    subheading: "NFT-backed",
    heading:
      "Secure Future with Precious Metal-backed NFTs",
    desc1:
      "Embrace a revolutionary approach to long-term wealth and financial security through our unique NFT redemption program. Each NFT holds the key to 10 grams of 999. Silver, ensuring a tangible asset to safeguard your prosperity. Additionally, for those desiring exquisite adornments, our exclusive jewelry redemption promises 10-13 grams, expertly crafted with a blend of metals, providing both durability and beauty.",
    desc2:
      "Your precious assets will be presented in an elegant gift bag, accompanied by an authentic certificate for each item, adding an extra layer of assurance to your investment. However, the crucial element lies in safeguarding your NFT, as its value is set to skyrocket at a predefined threshold, offering a strategic exit from the traditional fiat system.",
    desc3:
      "For the 4545 NFTs in circulation, the current allotment remains unchanged. NFT holders looking to redeem their silver or jewelry are required to undergo a KYC process, ensuring a secure and seamless delivery process. Seize this opportunity to transcend conventional financial structures and embark on a journey towards lasting prosperity.",
  });
  return (
    <section className="about" id="nft">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc1}</p>
              <p className="mb-26">{dataBlock.desc2}</p>
              <p className="mb-26">{dataBlock.desc3}</p>
              {/* <Button link="/about" title="More About Us" /> */}
            </div>
          </div>

          <div className="col-xl-6 col-md-12 d-flex align-items-center">
            <img
              src="/nfts.webp"
              width="100%"
              height="100%"
              style={{
                position: "relative",
                zIndex: 2,
                pointerEvents: "none",
                objectFit: "contain",
                maxHeight: 580,
                filter: "grayscale(100%) brightness(1)",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Nft;
