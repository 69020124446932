import Home from "./Home";
import Presale from "./Presale";
import Stake from "./Stake";

const routes = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/stake", component: <Stake /> },
  { path: "/presale", component: <Presale /> },
];

export default routes;
