import Web3 from "web3";

var web3;

if (typeof window !== "undefined") {
  if (typeof window.ethereum !== "undefined") {
    // Use Mist/MetaMask's provider.
    web3 = new Web3(Web3.givenProvider || window.ethereum);
  } else {
    // console.log('using infura provider')
    const infura = `https://sepolia.infura.io/v3/`;

    web3 = new Web3(new Web3.providers.HttpProvider(infura));
  }
}
export default web3;
