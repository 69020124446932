import img1 from "../../assets/images/icon/icon1.png";
import img2 from "../../assets/images/icon/icon2.png";
import img3 from "../../assets/images/icon/icon3.png";
import img4 from "../../assets/images/icon/icon4.png";

const AboutCardData = [
  {
    id: 1,
    img: img1,
    title: "Decentralized AI Marketplace",
    desc: "Providing a platform for AI developers to showcase and trade their models, fostering collaboration and knowledge sharing.",
    iconColor: "success",
    icon: "bi-shield-fill-check",
  },
  {
    id: 2,
    img: img2,
    title: "AI Oracles",
    desc: "Enabling AI algorithms to interact with real-world data on the blockchain, enhancing accuracy and unlocking new use cases.",
    iconColor: "primary",
    icon: "bi-shield-lock",
  },
  {
    id: 3,
    img: img3,
    title: "Governance Framework",
    desc: "Implementing decentralized governance for community-driven decision-making processes and platform enhancements.",
    iconColor: "info",
    icon: "bi-people-fill",
  },
  {
    id: 4,
    img: img4,
    title: "Tokenomics",
    desc: "The SIL token serves as the ecosystem's utility token, facilitating transactions, incentivizing participation, and powering governance activities.",
    iconColor: "danger",
    icon: "bi-wallet2",
  },
];

export default function AboutCard() {
  const AboutCards = AboutCardData.map((elem, index) => (
    <div className="col-12 col-sm-6 col-xl-3" key={index}>
      <div
        className="card about-card shadow-sm"
        style={{
          boxShadow: "0px 16px 36px rgba(0, 0, 0, 0.25)",
          borderRadius: "24px",
          position: "relative",
          background: "#0c153b",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          minHeight: 325,
        }}
      >
        <div className="card-body py-4">
          <div className={`icon bg-${elem.iconColor}`}>
            <i className={`bi ${elem.icon}`} />
          </div>
          <h4 className="mb-3" style={{ fontSize: 24 }}>
            {elem.title}
          </h4>
          <p className="mb-0">{elem.desc}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container">
      <div className="row g-4">{AboutCards}</div>
    </div>
  );
}
