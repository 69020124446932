import Web3 from "web3";
import { tokenAddress } from "./constants";
import tokenAbi from "./tokenAbi.json";

let constant = {
  contractAddress: tokenAddress,
  abi: tokenAbi,
};

var web3 = new Web3(window.ethereum);
var tokenContract = new web3.eth.Contract(
  constant.abi,
  constant.contractAddress
);

export default tokenContract;
