const dataTokenomics = [
  {
    id: 1,
    title: "Supply",
    desc: "Capped total supply to ensure scarcity and value preservation.",
    class: "",
  },
  {
    id: 2,
    title: "Distribution",
    desc: "Fair and transparent distribution to contributors, developers, and early adopters.",
    class: "right",
  },

  {
    id: 3,
    title: "Utility",
    desc: "Multiple functions within the ecosystem, including access to services, governance participation, and incentivization.",
    class: "",
  },

  {
    id: 4,
    title: "Deflationary Mechanism",
    desc: "Incorporating mechanisms to promote scarcity and value appreciation over time.",
    class: "right",
  },
  {
    id: 5,
    title: "Staking Rewards",
    desc: "Participants can earn rewards by staking SIL tokens to support network security and governance processes.",
    class: "",
  },
];

export default dataTokenomics;
