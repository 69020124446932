const dataRoadMap = [
  {
    id: 1,
    time: "Q3 2024",
    title: "Development",
    desc: "Building core infrastructure, including the marketplace, AI oracles, and governance framework.",
    class: "",
  },
  {
    id: 2,
    time: "Q4 2024",
    title: "Integration",
    desc: "Integrating with leading AI frameworks for interoperability and asset migration.",
    class: "right",
  },

  {
    id: 3,
    time: "Q1 2025",
    title: "Adoption",
    desc: "Partnering with industry leaders to onboard AI projects and drive ecosystem expansion.",
    class: "",
  },

  {
    id: 4,
    time: "Q2 2025",
    title: "Expansion",
    desc: "Exploring additional functionalities and use cases to enhance Soleil's capabilities, such as decentralized autonomous organizations (DAOs) for AI research.",
    class: "right",
  },
];

export default dataRoadMap;
