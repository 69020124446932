import { WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import App from "./App.js";
import { mainnet, sepolia } from "viem/chains";

const config = createConfig(
  getDefaultConfig({
    appName: "ConnectKit CRA demo",
    // chains: [sepolia],
    chains: [mainnet],
  })
);

function Main() {
  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider>
        <App />
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default Main;
