import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import { metaMask, metaMaskHooks } from "./utils/useMetamask";
import WagmiUtils from "./WagmiUtils";

const connectors = [[metaMask, metaMaskHooks]];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    {/* <Web3ReactProvider connectors={connectors}> */}
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <WagmiUtils />
      </BrowserRouter>
    </ThemeProvider>
    {/* </Web3ReactProvider> */}
  </React.Fragment>
);
