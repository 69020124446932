import React, { useState, useEffect } from "react";

const ProgressBar = ({ initialTime, timeLeft }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = (timeLeft / initialTime) * 100;
        return newProgress < 0 ? 0 : newProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, initialTime]);

  return (
    <div
      style={{
        width: "100%",
        height: 35,
        background: "#000",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        border: "1px solid rgba(255,255,255,0.2)",
        marginBottom: "15px",
      }}
    >
      <div style={{ position: "absolute", color: "#FFCA2B" }}>
        {progress.toFixed(2)}%
      </div>
      <div
        className="progress-bar-inner"
        style={{
          width: `${progress}%`,
          height: 35,
          background: "#1F0755",
          marginRight: "auto",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
