import React, { useState } from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import ReactApexChart from "react-apexcharts";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useMediaQuery } from "@mui/material";

Tokenomics.propTypes = {
  data: PropTypes.array,
};

function Tokenomics(props) {
  const { data } = props;
  const md = useMediaQuery("(max-width:768px)");

  const [dataBlock] = useState({
    subheading: "TOKENOMICS",
    heading: "Tokenomics for Soleil",
  });

  const options = {
    series: [80, 100, 120, 140, 160],
    labels: [
      "Supply",
      "Distribution",
      "Utility",
      "Deflationary Mechanism",
      "Staking Rewards",
    ],
    chart: {
      width: 380,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#111"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderWidth: 0,
      },
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: false,
    },
    title: {
      // text: "TOTAL SUPPLY - 4,000,000,000",
      style: {
        fontSize: 30,
        color: "#c2d4f8",
        fontFamily: "Bai Jamjuree",
      },
    },
  };
  return (
    <section className="roadmap s2" id="tokenomics">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h3 className="heading pdb">{dataBlock.heading}</h3>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: md && "column",
                gap: md ? "25px" : "50px",
              }}
            >
              <div style={{}}>
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="donut"
                  width={md ? 330 : 450}
                  height={md ? 330 : 450}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <p
                  style={{
                    color: "#c2d4f8",
                    fontSize: md ? 20 : 22,
                    fontFamily: "Bai Jamjuree",
                    textAlign: "justify",
                    maxWidth: 600,
                    margin: md && "0 auto",
                  }}
                >
                  The SIL token fuels the Soleil ecosystem with the following
                  key aspects:
                </p>
                <ul>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Symbol:</strong> SIL
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Total Supply:</strong> 1 billion (1,000,000,000)
                    tokens
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Distribution:</strong> Through token sales,
                    community incentives, and ecosystem development
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Utility:</strong> Access AI services, governance
                    voting, reward contributors
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Deflationary Mechanism:</strong> Token burning or
                    staking to reduce circulating supply
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Staking:</strong> 30% APR with lock times of 7, 14,
                    21, 30, and 60 days
                  </li>
                  <li
                    style={{
                      color: "#8480ae",
                      fontSize: md ? 16 : 18,
                      fontFamily: "Bai Jamjuree",
                      textAlign: "justify",
                      maxWidth: 600,
                    }}
                  >
                    <strong>Presale:</strong> Multi-stage with prices as
                    follows:
                    <br />
                    Stage 1: 0.001 USD per token (500,000 USD target)
                    <br />
                    Stage 2: 0.002 USD per token (250,000 USD target)
                    <br />
                    Stage 3: 0.0025 USD per token (125,000 USD target)
                    <br />
                    Stage 4: 0.003 USD per token (75,000 USD target)
                    <br />
                    Stage 5: 0.0035 USD per token (75,000 USD target)
                  </li>
                  <li>
                    <strong></strong>
                  </li>
                  <li>
                    <strong></strong>
                  </li>
                </ul>
                <p
                  style={{
                    color: "#c2d4f8",
                    fontSize: md ? 20 : 22,
                    fontFamily: "Bai Jamjuree",
                    textAlign: "justify",
                    maxWidth: 600,
                    margin: md && "0 auto",
                  }}
                >
                  Unsold tokens after presale will be burned. The presale will
                  be conducted on the Ethereum (ETH) chain.
                </p>
              </div>
              {/* - Symbol: SIL - Total Supply: 1 billion (1,000,000,000) tokens -
              Distribution: Through token sales, community incentives, and
              ecosystem development - Utility: Access AI services, governance
              voting, reward contributors - Deflationary Mechanism: Token
              burning or staking to reduce circulating supply - Staking: 30% APR
              with lock times of 7, 14, 21, 30, and 60 days - Presale:
              Multi-stage with prices as follows: - Stage 1: 0.001 USD per token
              (500,000 USD target) - Stage 2: 0.002 USD per token (250,000 USD
              target) - Stage 3: 0.0025 USD per token (125,000 USD target) -
              Stage 4: 0.003 USD per token (75,000 USD target) - Stage 5: 0.0035
              USD per token (75,000 USD target) Unsold tokens after presale will
              be burned. The presale will be conducted on the Ethereum (ETH)
              chain. */}
            </div>
            {/* <div className="roadmap__main s1">
              <Swiper
                spaceBetween={35}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  500: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
                className="roadmap-swiper"
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
              >
                {data.map((idx) => (
                  <SwiperSlide key={idx.id}>
                    <div className="roadmap-div">
                      <div className="content">
                        <h5 className="title">{idx.title}</h5>
                        <p className="text">{idx.desc}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;
