import React from "react";
import dataBox from "../assets/fake-data/data-box";
import dataFaqs from "../assets/fake-data/data-faq";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import dataTestimonials from "../assets/fake-data/data-testimonials";
import dataProject from "../assets/fake-data/dataProject";
import dataTeam from "../assets/fake-data/dataTeam";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Create from "../components/create/Create";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import WhyUs from "../components/WhyUs";
import Project from "../components/project/Project";
import Roadmap from "../components/roadmap/Roadmap";
import Speciality from "../components/speciality/Speciality";
import Tokenomics from "../components/Tokenomics";
import dataTokenomics from "../assets/fake-data/data-tokenomics";
import Nft from "../components/nft/Nft";
import AboutCard from "../components/about/AboutCard";
import AboutContent from "../components/about/AboutContent";
import CTA from "../components/about/CTA";

function Home(props) {
  return (
    <div
      style={{
        width: "100%",
        background: "#030205",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 1440,
          margin: "0 auto",
          padding: "0 7%",
        }}
      >
        <Banner />

        <div className="divider" id="features"/>
        <AboutCard />

        <div className="divider" id="about"/>

        <AboutContent />

        <Roadmap data={dataRoadMap} />

        <Tokenomics data={dataTokenomics} />

        <Faqs data={dataFaqs} />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
